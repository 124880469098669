import React, { FC } from "react"
import { Link as GatsbyLink } from "gatsby"

// import autoscroll from "../utils/autoscroll"

// interface ScrollToProps {
//   to: string,
//   className?: string
// }

// const ScrollTo: FC<ScrollToProps> = ({
//   to,
//   className,
//   children
// }) => {
//   const scroll = (e: React.MouseEvent) => {
//     e.preventDefault()
//     e.stopPropagation()
//     autoscroll({
//       destination: document.querySelector(to)
//     })
//   }

//   return (
//     <a
//       href={`/${to}`}
//       onClick={scroll}
//       className={className}
//     >
//       {children}
//     </a>
//   )
// }

// {/* <ScrollTo
//   className="font-secondary mr-6 lg:mr-3 md:mt-2"
//   to="#mulighetene"
// >
//   Muligheter
// </ScrollTo>
// <ScrollTo
//   className="font-secondary mx-6 lg:mx-3 md:mt-2"
//   to="#om-bonum"
// >
//   Om oss
// </ScrollTo>
// <ScrollTo
//   className="font-secondary mx-6 lg:mx-3 md:mt-2"
//   to="#tjenester"
// >
//   Tjenester
// </ScrollTo>
// <ScrollTo
//   className="font-secondary mx-6 lg:mx-3 md:mt-2"
//   to="#prosjekter"
// >
//   Prosjekter
// </ScrollTo> */}

export interface LinkProps {
  text: string,
  href: string
}

const Link: FC<any> = ({ to, ...props }) => ((
  to.startsWith("http") ||
  to.startsWith("mailto") ||
  to.startsWith("tlf")
) ? (
    <a
      href={to}
      {...props}
    />
  ) : (
    <GatsbyLink
      to={to}
      activeClassName="active"
      partiallyActive={true}
      {...props}
    />
  )
)

export default Link
