import React, { FC, forwardRef } from "react"
import cn from "../utils/cn"

const InputField = forwardRef(
  (props: any, ref: any) => <input {...props} ref={ref} />
)

const TextareaField = forwardRef(
  (props: any, ref: any) => <textarea {...props} ref={ref} />
)

interface InputProps {
  label?: string,
  name: string,
  placeholder?: string,
  type?: string,
  required?: boolean,
  error?: string,
  register: any,
  errors: any,
  className?: string,
  fieldClassName?: string,
  labelClassName?: string
}

const Input: FC<InputProps> = ({
  label,
  name,
  placeholder,
  type,
  required,
  register,
  errors,
  className,
  fieldClassName,
  labelClassName
}) => {
  return (
    <label
      htmlFor={name}
      className={cn("input-group", className)}
    >
      {label && (
        <div className={cn("input-label", labelClassName)}>
          {required && (
            <span className="text-red-600 mr-1">*</span>
          )}
          {label}
        </div>
      )}

      {type === "textarea" ? (
        <TextareaField
          className={cn("input-field", fieldClassName)}
          rows={5}
          name={name}
          id={name}
          placeholder={placeholder}
          ref={register({ required })}
        />
      ) : (
        <InputField
          className={cn("input-field", fieldClassName)}
          name={name}
          id={name}
          type={type || "text"}
          placeholder={placeholder}
          ref={register({ required })}
        />
      )}

      {errors[name] && (
        <p className="input-error">
          {label} er påkrevet
        </p>
      )}
    </label>
  )
}

export default Input
