import React, { FC } from "react"
import Img, { ImgProps } from "../components/Img"
import NewsletterForm from "../components/NewsletterForm"
import Link, { LinkProps } from "../components/Link"
import { useStaticQuery, graphql } from "gatsby"

const FooterLink: FC<any> = (props) => (
  <div className="mb-2">
    <Link {...props} />
  </div>
)

interface SocialMediaNavItem {
  to: string,
  icon: ImgProps
}

type Nav = LinkProps[]

interface FooterNav {
  label: string,
  nav: Nav
}

interface PageFooterProps {
  socialMediaNav: SocialMediaNavItem[],
  contactNav: Nav,
  nav1: FooterNav,
  nav2: FooterNav,
  nav3: FooterNav,
  logo: ImgProps,
  copyrightText: string
}

const PageFooter: FC<PageFooterProps> = ({
  socialMediaNav,
  contactNav,
  nav1,
  nav2,
  nav3,
  logo,
  copyrightText
}) => (
  <footer className="text-sm text-gray-600 bg-black">
    <section className="w-full px-6 pb-12 pt-10">
      <div className="w-full max-w-4xl mx-auto flex items-center sm:flex-col">
        <div className="flex-2 sm:flex-none sm:w-full">
          <NewsletterForm />
        </div>

        <nav
          role="navigation"
          className="flex-1 flex justify-end sm:flex-none sm:items-center sm:mt-12"
        >
          {socialMediaNav.map(({
            to,
            icon
          }) => (
            <Link
              key={to}
              className="ml-4 hover:opacity-50"
              target="_blank"
              rel="noopener"
              to={to}
              title={icon.alt}
            >
              <span className="hidden">
                {icon.alt}
              </span>

              <Img
                src={icon.src}
                alt={icon.alt}
                className="w-4"
              />
            </Link>
          ))}
        </nav>
      </div>
    </section>

    <section className="w-full px-6 pt-6 pb-12 border-t-2 border-b-2 border-gray-900 border-solid">
      <div className="w-full max-w-4xl mx-auto flex justify-between flex-wrap">
        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <Img
            src={logo.src}
            alt={logo.alt}
            className="mb-3 xs:mx-auto w-32"
          />

          {contactNav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
              target="_blank"
              rel="noopener"
            >
              {text}
            </FooterLink>
          ))}
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-5 text-base text-white tracking-widest uppercase">
            {nav1.label}
          </h3>

          {nav1.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-5 text-base text-white tracking-widest uppercase">
            {nav2.label}
          </h3>

          {nav2.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center"
        >
          <h3 className="mb-5 text-base text-white tracking-widest uppercase">
            {nav3.label}
          </h3>

          {nav3.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>
      </div>
    </section>

    <section className="w-full px-6 py-6 text-center">
      <p>
        {copyrightText}
      </p>
    </section>
  </footer>
)

export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query PageFooter {
        markdownRemark(
          frontmatter: {
            component: {
              eq: "PageFooter"
            }
          }
        ) {
          frontmatter {
            socialMediaNav {
              to
              icon {
                src {
                  childImageSharp {
                    fluid(maxWidth: 36 quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
            contactNav {
              to
              text
            }
            nav1 {
              label
              nav {
                to
                text
              }
            }
            nav2 {
              label
              nav {
                to
                text
              }
            }
            nav3 {
              label
              nav {
                to
                text
              }
            }
            copyrightText
            logo {
              src {
                childImageSharp {
                  fluid(maxHeight: 60 quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    `
  )

  const {
    socialMediaNav,
    contactNav,
    nav1,
    nav2,
    nav3,
    copyrightText,
    logo
  } = markdownRemark.frontmatter

  return (
    <PageFooter
      socialMediaNav={socialMediaNav}
      contactNav={contactNav}
      nav1={nav1}
      nav2={nav2}
      nav3={nav3}
      copyrightText={copyrightText}
      logo={logo}
    />
  )
}
