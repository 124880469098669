import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { disableElementScroll, enableElementScroll } from "../utils/lockscroll"
import cn from "../utils/cn"

export interface DialogProps {
  title: React.ReactNode | string,
  shown: boolean,
  onClose?: Function,
  onOpen?: Function,
  width?: number | string,
  toBody?: boolean,
  elementToLock?: string,
  floatingTitle?: boolean,
  className?: string
}

const Dialog: React.FC<DialogProps> = ({
  title,
  shown,
  children,
  onClose,
  onOpen,
  width,
  toBody,
  elementToLock,
  floatingTitle,
  className
}) => {
  const [ fullscreen, setFullscreen ] = useState<boolean>(false)
  if(!width) width = 900

  const close = () => {
    if(elementToLock) {
      const element = document.querySelector(elementToLock)
      if(element) enableElementScroll(element)
    }
    shown = false
    if(onClose) onClose()
  }

  const open = () => {
    if(elementToLock) {
      const element = document.querySelector(elementToLock)
      if(element) disableElementScroll(element)
    }
    if(onOpen) onOpen()
  }

  useEffect(() => {
    if(shown) open()

    const keydown = (e: KeyboardEvent) => {
      if(e.keyCode !== 27) return
      close()
    }

    const onMatch = (e: MediaQueryListEvent) => {
      setFullscreen(e.matches)
    }
    const mql = window.matchMedia(`(max-width: ${width}${typeof width === "number" ? 'px' : ''})`)

    mql.addListener(onMatch)
    document.addEventListener("keydown", keydown, false)

    return () => {
      mql.removeListener(onMatch)
      document.removeEventListener("keydown", keydown, false)
    }
  }, [
    shown
  ])

  const Dialog = (
    <div
      className={cn(`fixed z-20 inset-0 w-full h-full overflow-y-auto scrolling-touch flex backdrop ${fullscreen ? "py-0" : "py-10"}`, className)}
      onClick={() => { close() }}
    >
      <div
        className={`border-gray-900 border border-solid m-auto shadow bg-black text-white w-full ${fullscreen ? 'rounded-none min-h-full flex flex-col' : 'rounded'} ${floatingTitle ? "relative" : ""}`}
        style={{ maxWidth: width }}
        onClick={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
        }}
      >
        <div
          className={`py-3 px-6 flex items-center justify-between ${floatingTitle ? "absolute inset-x-0 top-0" : "border-b border-solid border-gray-900"}`}
        >
          <h2 className="font-semibold text-lg mb-0">
            {title}
          </h2>
          <button
            className="text-3xl leading-none hover:opacity-50"
            onClick={() => close()}
          >
            &times;
          </button>
        </div>
        {children}
      </div>
    </div>
  )

  return shown ? (
    toBody ? ReactDOM.createPortal(Dialog, document.body) : Dialog
  ) : null
}

export default Dialog
