import React, { FC, CSSProperties } from "react"
import GatsbyImage from "gatsby-image"
import LazyLoad from "react-lazyload"
import LazyLoadPlaceholder from "./LazyLoadPlaceholder"
import config from "../config"

export type ImgProps = Readonly<{
  src: any,
  alt?: string,
  style?: CSSProperties,
  className?: string,
  imgStyle?: Object,
  loading?: "auto" | "lazy" | "eager",
  fadeIn?: boolean
}>

const Img: FC<ImgProps> = (props) => {
  let Img = null
  let src = props.src

  if(config.env === "development") {
    if(typeof props.src === "string" && props.src.includes("uploads")) {
      src = `https://bonum-no.netlify.app${src}`
    }
  }

  if(typeof props.src === "string") {
    Img = <img
      src={src}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else if(props.src.extension === "svg") {
    Img = <img
      src={props.src.publicURL}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else {
    Img = <GatsbyImage
      fluid={props.src.childImageSharp.fluid}
      alt={props.alt}
      style={props.style}
      imgStyle={props.imgStyle}
      className={props.className}
      loading="eager"
      fadeIn={false}
    />
  }

  if(typeof props.src === "string") {
    return (
      <LazyLoad
        height={200}
        offset={200}
        placeholder={<LazyLoadPlaceholder />}
        once
        preventLoading={false}
      >
        {Img}
      </LazyLoad>
    )
  }

  return Img
}

export default Img
