import React, { useState, useEffect } from "react"
import Dialog from "./Dialog"
import ActivityIndicator from "./ActivityIndicator"

interface C {
  question: string,
  required: boolean,
  value: boolean
}

interface Consent {
  question: string,
  required: boolean
}

interface GdprDialogProps {
  shown: boolean,
  onAccept: Function,
  onDeny: Function,
  isLoading: boolean,
  description: string,
  consents: Consent[]
}

const GdprDialog: React.FC<GdprDialogProps> = ({
  shown,
  onAccept,
  onDeny,
  isLoading,
  description,
  consents
}) => {
  const [ c, setC ] = useState<C[]>([])
  const [ hasRequiredConsents, setHasRequiredConsents ] = useState(false)

  useEffect(() => {
    setC(consents.map(
      (consent) => ({ ...consent, value: false })
    ))
  }, [ consents ])

  const onChangeConsent = (index: number, value: boolean) => {
    setC(c.map((consent, i) => {
      if(i === index) consent.value = value
      return consent
    }))

    const accepts = c.filter(
      ({ value, required }) => value === true && required === true
    )

    const required = c.filter(
      ({ required }) => required === true
    )

    if(accepts.length >= required.length) {
      setHasRequiredConsents(true)
    }
  }

  return (
    <Dialog
      title="Vennligst les før du fortsetter"
      shown={shown}
      width={375}
      onClose={() => { onDeny() }}
      className="text-base"
    >
      <ActivityIndicator
        shown={isLoading}
      >
        Sender..
      </ActivityIndicator>

      <div className="p-6">
        <p className="mb-4">{description}</p>

        <p>Du kan lese mer om <a href="https://bonum.no/personvern" target="_blank" className="text-accent light hover:text-white">vårt forhold til personvern</a>, for å finne ut om hvordan vi best ivaretar din informasjon.</p>

        <div className="mt-10">
          {c.map((consent: Consent, index: number) => (
            <div
              className="flex items-center mt-4"
              key={index}
            >
              <input
                id={`consent-${index}`}
                name={`consent-${index}`}
                type="checkbox"
                onChange={(e) => {
                  onChangeConsent(index, e.target.checked)
                }}
              />

              <label
                className="ml-6"
                htmlFor={`consent-${index}`}
              >
                {consent.question}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="border-t border-solid border-gray-900 p-6 mt-3">
        <button
          className="btn btn-primary btn-sm w-full"
          onClick={() => { onAccept() }}
          disabled={!hasRequiredConsents}
        >
          Jeg aksepterer
        </button>
      </div>
    </Dialog>
  )
}

export default GdprDialog
