import React, { FC } from "react"
import { useGlobal } from "reactn"
import Helmet from "react-helmet"
import "../design/resets.css"
import "../design/styles.css"
import Meta from "../components/Meta"
import PageFooter from "../components/PageFooter"
import ContactDialog from "../components/ContactDialog"
import "react-toastify/dist/ReactToastify.min.css"
import { toast } from "react-toastify"

toast.configure({
  hideProgressBar: true
})

interface Layout<T> extends FC<T> {
  pageContext?: any
}

const DefaultLayout: Layout<any> = ({ children }) => {
  const [
    showContactDialog,
    setShowContactDialog
  ] = useGlobal<any>("showContactDialog")

  return (
    <div id="default-layout">
      <Meta />

      <Helmet>
        <html lang="no" />
        <meta name="theme-color" content="#052033" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="google-site-verification" content="GT85yZXrAFeWqDFu1n7VgmFWrBl_ygwK2Yz-MhQzJlY" />
        <meta property="og:locale" content="nb_NO" />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="1253609801428791" />
        <link rel="icon" type="image/png" href="/img/bonum-icon.png" />
        <link rel="stylesheet" href="https://use.typekit.net/obr5oxh.css" />
      </Helmet>

      {children}

      <PageFooter />

      <ContactDialog
        shown={showContactDialog}
        onClose={() => { setShowContactDialog(false) }}
      />
    </div>
  )
}

export default DefaultLayout
