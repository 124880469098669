// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-prosjekter-prosjekt-tsx": () => import("./../../../src/pages/prosjekter/prosjekt.tsx" /* webpackChunkName: "component---src-pages-prosjekter-prosjekt-tsx" */),
  "component---src-templates-ansatte-page-tsx": () => import("./../../../src/templates/AnsattePage.tsx" /* webpackChunkName: "component---src-templates-ansatte-page-tsx" */),
  "component---src-templates-document-page-tsx": () => import("./../../../src/templates/DocumentPage.tsx" /* webpackChunkName: "component---src-templates-document-page-tsx" */),
  "component---src-templates-entreprise-page-tsx": () => import("./../../../src/templates/EntreprisePage.tsx" /* webpackChunkName: "component---src-templates-entreprise-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/IndexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-karriere-page-tsx": () => import("./../../../src/templates/KarrierePage.tsx" /* webpackChunkName: "component---src-templates-karriere-page-tsx" */),
  "component---src-templates-kundeservice-page-tsx": () => import("./../../../src/templates/KundeservicePage.tsx" /* webpackChunkName: "component---src-templates-kundeservice-page-tsx" */),
  "component---src-templates-nearing-prosjekter-page-tsx": () => import("./../../../src/templates/NearingProsjekterPage.tsx" /* webpackChunkName: "component---src-templates-nearing-prosjekter-page-tsx" */),
  "component---src-templates-not-found-page-tsx": () => import("./../../../src/templates/NotFoundPage.tsx" /* webpackChunkName: "component---src-templates-not-found-page-tsx" */),
  "component---src-templates-nxt-page-tsx": () => import("./../../../src/templates/NxtPage.tsx" /* webpackChunkName: "component---src-templates-nxt-page-tsx" */),
  "component---src-templates-partnerskap-page-tsx": () => import("./../../../src/templates/PartnerskapPage.tsx" /* webpackChunkName: "component---src-templates-partnerskap-page-tsx" */),
  "component---src-templates-prosjekter-page-tsx": () => import("./../../../src/templates/ProsjekterPage.tsx" /* webpackChunkName: "component---src-templates-prosjekter-page-tsx" */),
  "component---src-templates-takk-for-interessen-page-tsx": () => import("./../../../src/templates/TakkForInteressenPage.tsx" /* webpackChunkName: "component---src-templates-takk-for-interessen-page-tsx" */),
  "component---src-templates-utvikling-page-tsx": () => import("./../../../src/templates/UtviklingPage.tsx" /* webpackChunkName: "component---src-templates-utvikling-page-tsx" */),
  "component---src-templates-video-page-tsx": () => import("./../../../src/templates/VideoPage.tsx" /* webpackChunkName: "component---src-templates-video-page-tsx" */)
}

