import React, { FC } from "react"
import ActivityIndicator from "./ActivityIndicator"

interface LazyLoadPlaceholderProps {
  height?: number
}

const LazyLoadPlaceholder: FC<LazyLoadPlaceholderProps> = ({ height }) => (
  <div
    className="relative w-full"
    style={{ height }}
  >
    <ActivityIndicator
      shown={true}
      className="backdrop-none"
      children={null}
    />
  </div>
)

LazyLoadPlaceholder.defaultProps = {
  height: 200
}

export default LazyLoadPlaceholder
