import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import cn from "../utils/cn"

const Spinner = styled.div`
  &:after {
    border-radius: inherit;
  }
  & {
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #1544b3;
    transform: translateZ(0);
    animation: rotate 1.1s infinite linear;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

interface ActivityIndicatorProps {
  shown?: boolean,
  children?: ReactNode,
  className?: string
}

const ActivityIndicator: FC<ActivityIndicatorProps> = ({
  shown, children, className
}) => shown ? (
  <div
    className={cn("absolute inset-0 flex flex-col items-center justify-center text-white backdrop", className)}
  >
    <Spinner
      className="rounded-full w-8 h-8 block mb-4"
    />

    {children}
  </div>
) : null

ActivityIndicator.defaultProps = {
  children: "Laster.."
}

export default ActivityIndicator
