import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import GdprDialog from "./GdprDialog"
import Input from "./Input"

toast.configure({
  hideProgressBar: true
})

type FormProps = {
  email: string
}

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const NewsletterForm: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<FormProps>()
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ showGdprDialog, setShowGdprDialog ] = useState<boolean>(false)
  const [ formData, setFormData ] = useState<FormProps | null>(null)

  const submit = async () => {
    setIsLoading(true)

    try {
      const res = await fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode({
          "form-name": "newsletter",
          ...formData
        })
      })

      if(!res.ok) {
        throw new Error("En ukjent feil oppstod")
      }

      toast.success("Du er nå meldt opp på nyhetsbrevet")
      setShowGdprDialog(false)
    } catch(e) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  const onSubmit = (data: FormProps): void => {
    setFormData(data)
    setShowGdprDialog(true)
  }

  return (
    <>
      <form
        action="/"
        name="newsletter"
        onSubmit={handleSubmit(onSubmit)}
        className="text-sm flex items-end sm:text-center sm:items-center sm:flex-col"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="form-name"
          value="newsletter"
        />

        <Input
          name="emailAddress"
          label="Meld deg på nyhetsbrevet"
          labelClassName="sm:justify-center"
          placeholder="Skriv inn din e-post adresse"
          className="flex-1 mr-8 sm:mr-0 sm:w-full sm:mb-4"
          fieldClassName="py-1 bg-gray-900 border-gray-900 text-white sm:text-center"
          type="email"
          required={false}
          register={register}
          errors={errors}
        />

        <button
          type="submit"
          className="btn border-2 font-semibold border-solid border-gray-800 text-white px-6 pt-3 pb-2 hover:bg-gray-800 uppercase text-xs tracking-wide"
        >
          Motta nyheter
        </button>
      </form>

      <GdprDialog
        shown={showGdprDialog}
        onAccept={submit}
        onDeny={() => setShowGdprDialog(false)}
        isLoading={isLoading}
        description="Dette skjemaet samler inn din e-post adresse slik at vi kan sende deg nyhetsbrev."
        consents={[{
          question: "Jeg samtykker til at Bonum kan samle på min e-post adresse.",
          required: true
        }]}
      />
    </>
  )
}

export default NewsletterForm
