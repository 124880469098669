const preventDefault = (e: MouseWheelEvent | MouseEvent | WheelEvent | TouchEvent) => {
  e = e || window.event
  if(e.preventDefault) e.preventDefault()
  e.returnValue = false
}

export const disableWindowScroll = () => {
  window.onwheel = preventDefault
  window.ontouchmove = preventDefault
}

export const enableWindowScroll = () => {
  window.onwheel = null
  window.ontouchmove = null
  document.onkeydown = null
}

export const disableBodyScroll = () => {
  window.document.body.style.overflow = "hidden"
  // window.document.body.style.position = "relative"
  window.document.documentElement.style.overflow = "hidden"
  // window.document.documentElement.style.position = "relative"
  // window.document.body.addEventListener("touchmove", preventDefault, false)
}

export const enableBodyScroll = () => {
  window.document.body.style.overflow = ""
  // window.document.body.style.position = ""
  window.document.documentElement.style.overflow = ""
  // window.document.documentElement.style.position = ""
  // window.document.body.removeEventListener("touchmove", preventDefault, false)
}

export const disableElementScroll = (element: Element) => {
  element.style["overflow-y"] = "hidden"
}

export const enableElementScroll = (element: Element) => {
  element.style["overflow-y"] = "auto"
}
