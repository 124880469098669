import React, { FC, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import GdprDialog from "./GdprDialog"
import config from "../config"
import Input from "./Input"
import Dialog from "./Dialog"
import { useStaticQuery, graphql } from "gatsby"
import marked from "marked"

interface FormData {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber?: string,
  message: string
}

interface ContactDialogProps {
  shown: boolean,
  onClose: Function
}

interface ContactDialogTemplateProps extends ContactDialogProps {
  html: string
}

const ContactDialogTemplate: FC<ContactDialogTemplateProps> = ({
  shown,
  onClose,
  html
}) => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ showGdprDialog, setShowGdprDialog ] = useState<boolean>(false)
  const [ formData, setFormData ] = useState<FormData>()

  const submit = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${config.apiUrl}/hovedsider/inquiries`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "Noe gikk galt med innsending av skjemaet. Vennligst kontakt oss via e-post eller telefon isteden.")
      }

      toast.success("Takk for din henvendelse. Vi tar kontakt med deg så snart som mulig")
      setShowGdprDialog(false)
    } catch(e) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  const onSubmit = (data: FormData): void => {
    setFormData(data)
    setShowGdprDialog(true)
  }

  return (
    <>
      <Dialog
        title="Ta kontakt"
        shown={shown}
        onClose={onClose}
        elementToLock="body"
        width={460}
      >
        <div
          className="p-6 markdown"
          dangerouslySetInnerHTML={{
            __html: marked(html)
          }}
        />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-sm"
        >
          <div className="px-6">
            <Input
              name="firstName"
              label="Fornavn"
              placeholder="Skriv inn ditt fornavn"
              className="mb-5 block"
              required={true}
              register={register}
              errors={errors}
            />

            <Input
              name="lastName"
              label="Etternavn"
              placeholder="Skriv inn ditt etternavn"
              className="mb-5 block"
              required={true}
              register={register}
              errors={errors}
            />

            <Input
              name="email"
              label="E-post"
              placeholder="Skriv inn din e-post addresse"
              className="mb-5 block"
              required={true}
              register={register}
              errors={errors}
            />

            <Input
              name="phone"
              label="Telefonnummer"
              placeholder="Skriv inn ditt telefonnummer"
              className="mb-5 block"
              required={false}
              register={register}
              errors={errors}
            />

            <Input
              type="textarea"
              name="message"
              label="Melding"
              placeholder="Skriv inn en melding"
              required={true}
              register={register}
              errors={errors}
            />
          </div>

          <div className="border-t border-solid border-gray-900 p-6 mt-6">
            <button
              type="submit"
              className="btn btn-primary w-full block"
            >
              Ta kontakt
            </button>
          </div>
        </form>
      </Dialog>

      <GdprDialog
        shown={showGdprDialog}
        onAccept={submit}
        onDeny={() => setShowGdprDialog(false)}
        isLoading={isLoading}
        description="Dette skjemaet samler inn ditt navn, din e-post adresse, og telefonnummer (valgfritt), slik at vi kan kontakte deg når vi behandler henvendelsen."
        consents={[{
          question: "Jeg samtykker til at Bonum kan samle på informasjon i skjemaet.",
          required: true
        }]}
      />
    </>
  )
}

const ContactDialog: FC<ContactDialogProps> = ({
  shown,
  onClose
}) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query ContactDialog {
        markdownRemark(
          frontmatter: {
            component: {
              eq: "ContactDialog"
            }
          }
        ) {
          html
        }
      }
    `
  )

  return (
    <ContactDialogTemplate
      shown={shown}
      onClose={onClose}
      html={markdownRemark.html}
    />
  )
}

export default ContactDialog
